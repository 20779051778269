import React from "react";
import ProfileForm from '../containers/ProfileForm';
import Header from '../containers/Header';


function Profile () {

  return (
    <div>
      <Header />
      <ProfileForm />
    </div>

  );
}

export default Profile;
