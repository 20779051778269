import React from "react";
import TransactionsList from '../containers/TransactionList';
import Header from '../containers/Header';


function Transactions() {

  return (
    <div>
      <Header />
      <TransactionsList />
    </div>

  );
}

export default Transactions;
