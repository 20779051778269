import React from "react";
import ChangePasswordForm from '../containers/ChangePasswordForm';
import Header from '../containers/Header';


function ChangePassword() {

  return (
    <div>
      <Header />
      <ChangePasswordForm />
    </div>

  );
}

export default ChangePassword;
