import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../state';


export const PrivateRoute = ({ component: Component, ...rest }) =>  {
    
    const { state } = useContext(AuthContext);
    return (
    <Route {...rest} render={props => (
        // localStorage.getItem('user')
        state.isAuthenticated
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)}