import React from "react";
import CashoutReportForm from '../containers/CashoutReportForm';
import Header from '../containers/Header';


function CashoutReport() {

  return (
    <div>
      <Header />
      <CashoutReportForm />
    </div>
  );
}

export default CashoutReport;
