import React from "react";
import TransactionsDetails from '../containers/TransactionDetails';
import Header from '../containers/Header';


function TransactionsDetail(props) {

  return (
    <div>
      <Header />
      <TransactionsDetails {...props} />
    </div>

  );
}

export default TransactionsDetail;
