
class DataParser { 

    static getTransaction(tran) {
        if(tran === null) {
            return {}
        }
        let transaction = {
            code: tran.data.attributes.code,
            status: tran.data.attributes.status,
            status_date: tran.data.attributes.status_date,
            log_date: tran.data.attributes.log_date,
            amount: tran.data.attributes.amount,
            id: tran.data.id
        };
       return transaction;
    }

    static getSender(tran) {
        if(tran === null) {
            return {}
        }
        let sender = null;
       // console.log(JSON.stringify(tran))
        tran.included.forEach(element => {
            if(element.id === tran.data.relationships.sender.data.id){
                sender = {
                    code: tran.included[0].attributes.code,
                    firstName: tran.included[0].attributes.first_name,
                    middleName: tran.included[0].attributes.middle_name,
                    lastName: tran.included[0].attributes.last_name 
                }
            }
            
        });
        
       return sender;
    }

    static getBeneficiary(tran) {
        if(tran === null) {
            return {}
        }
        let beneficiary = null;
        //console.log(JSON.stringify(tran))
        tran.included.forEach(element => {
            if(element.id === tran.data.relationships.beneficiary.data.id){
                beneficiary = {
                    code: tran.included[1].id,
                    name: tran.data.attributes.beneficiary_name
                }
            }
            
        });

        console.log("TESTSING ", beneficiary, tran);
        
       return beneficiary;
    }


    static getProfile(data) {
        
    }

    static getCashCollectionBranchDetails = (element, included) => {
        const { type, id } = element.relationships.payout_branch.data;
        
        // payout_branch object
        const payoutBranch = included.find(include => include.type === type && include.id === id);
        
        // cash pick up locations
        const { name: locationName, city } = payoutBranch.attributes;

        const { country, provider } = payoutBranch.relationships;

        const { type: countryType, id: countryId } = country.data;

        const { type: providerType, id: providerId } = provider.data;

        // country object
        const countryObj = included.find(include => include.type === countryType && include.id === countryId);
        
        // provider object
        const providerObj = included.find(include => include.type === providerType && include.id === providerId);

        const { name: countryName } = countryObj.attributes;
        const { name: providerName } = providerObj.attributes;

        const cashCollectionBranch = `${providerName} - ${locationName}`

        return cashCollectionBranch;
    }

    static getTransactions(trans, included){
        //console.log(JSON.stringify(trans))
        //console.log(JSON.stringify(included))
        var transData = [];
        let status = "";
        trans.forEach(element => {
            let stat = element.attributes.status;
            if(stat === 'A'){
                status = "AUTHORISED";
            }else if(stat === 'C'){
                status = "COMPLETED";
            }else if(stat === 'P'){
                status = "PENDING";
            }else {
                status = "STATUS";
            }
            let sender ;
            let beneficiary;
            let currency;

            included.forEach(include => {
                //console.log("For each >>>>>>>>>>>>>.. " + include.id + " --------------" + element.relationships.sender.data.id)

                if(element.relationships.sender.data.id === include.id && include.type === 'accounts'){
                   // console.log("Getting for sender >>>>>>>>>>>>>.. " )
                    sender = include.attributes.first_name+ "" + include.attributes.middle_name + " "+ include.attributes.last_name 
                } 
                
                if(element.relationships.beneficiary.data.id  === include.id && include.type === 'beneficiaries'){
                    beneficiary = include.attributes.name
                }  

                if(element.relationships.currency.data.id  === include.id && include.type === 'currencies'){
                    currency = include.attributes.letter_code
                }  

            });

            //console.log("Sender : " + JSON.stringify(sender) + "    Beneficiary: " + JSON.stringify(beneficiary))

            transData.push({
                 sender: sender,
                 beneficiary: beneficiary,
                 code: element.attributes.code,
                 beneficiary_name: element.attributes.beneficiary_name,
                 amount: currency + ' '+  element.attributes.amount,
                 id: element.id,
                 status: status,
                 status_date: element.attributes.status_date,
            })            
        });
        return transData;
    }

    static getCashoutReport(trans, included){
        if(trans === null){
        console.log("Icho oooooooooooooo" + trans);
        }
        var transData = [];
        if(trans !== null){
        let status = "";
        trans.forEach(element => {
            let stat = element.attributes.status;
            if(stat === 'A'){
                status = "AUTHORISED";
            }else if(stat === 'C'){
                status = "COMPLETED";
            }else if(stat === 'P'){
                status = "PENDING";
            }else {
                status = "STATUS";
            }
            let sender ;
            let beneficiary;
            let currency;
            let cash_collection_branch;
            let agent;

            included.forEach(include => {
                //console.log("For each >>>>>>>>>>>>>.. " + include.id + " --------------" + element.relationships.sender.data.id)

                if(element.relationships.sender.data.id === include.id && include.type === 'accounts'){
                   // console.log("Getting for sender >>>>>>>>>>>>>.. " )
                    sender = include.attributes.first_name+ "" + include.attributes.middle_name + " "+ include.attributes.last_name 
                } 
                
                if(element.relationships.beneficiary.data.id  === include.id && include.type === 'beneficiaries'){
                    beneficiary = include.attributes.name
                }  

                if(element.relationships.currency.data.id  === include.id && include.type === 'currencies'){
                    currency = include.attributes.letter_code
                }  

                if(element.relationships.payout_agent.data.id  === include.id && include.type === 'accounts'){
                    agent = include.attributes.first_name + " " + include.attributes.last_name;
                }
                
                if(element.relationships.payout_branch.data.id  === include.id && include.type === 'cash_pickup_locations'){
                    cash_collection_branch = DataParser.getCashCollectionBranchDetails(element, included);
                }  
                
            });

            //console.log("Sender : " + JSON.stringify(sender) + "    Beneficiary: " + JSON.stringify(beneficiary))

            transData.push({
                 sender: sender,
                 beneficiary: beneficiary,
                 code: element.attributes.code,
                 amount: currency + ' '+  element.attributes.amount,
                 id: element.id,
                 status: status,
                 status_date: element.attributes.status_date,
                 cash_collection_branch,
                 agent: agent
            })            
        });
    }
    return transData;
    }

}

export default DataParser;