import React from "react";
import PasswordResetForm from '../containers/PasswordResetForm';


function ResetPassword() {

  return (
    <PasswordResetForm />
  );
}

export default ResetPassword;