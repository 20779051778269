import React, { useState } from "react";
import { Link } from "react-router-dom"
import '../styles/login.css';
import '../styles/util.css';
import logo from '../images/sasai-money-transfer-logo.png';
import config from '../env-config.json';

function PasswordResetForm() {
  // constructor() {
  //   super();
  //   this.state = {
  //     username: "",
  //     password: "",
  //     submitted: false

  //   };
  //   this.handleChange = this.handleChange.bind(this);
  //   this.handleSubmit = this.handleSubmit.bind(this);
  // }

  const [username, setUsername] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [success, setSuccess] = useState(false);

  // const handleChange = (event) => {
  //   this.setState({ [event.target.id]: event.target.value });
  // }

  const handleSubmit = (event) => {
    /*
  event.preventDefault();
  const { username } = this.state;
  const { password } = this.state;
  const { submitted } = this.state;
  //const id = uuidv1();
  ServerData.login(username,password);
  this.setState(
      {   username: "" ,
          password: "",
          submitted: false
      });
      */
    event.preventDefault();
    console.log("email from change", username);
    // const email = this.state.username;

    let requestObject = {
      "data": {
        "type": "passwords",
        "id": null,
        "attributes": {
          "email": username
        }
      }
    }

    fetch(config.api_url + 'email/passwords/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json',
        'App-Id': config.app_id,
      },
      body: JSON.stringify(requestObject)
    })
      .then((response) => {
        console.log(response)
        if (response.status === 201) {
          response.json().then(
            res => {
            // error message from backend
            console.log("Response", res);
            setHasError(false);
            setErrorMessage('');
            setSuccess(true);
            setSuccessMessage('Please check your email to reset your password');
          })
        
        } else if (response.status === 400) {
          response.json().then(
            res => {
            // error message from backend
            console.log("Response", res.errors[0].detail);
            setHasError(true);
            setErrorMessage(res.errors[0].detail);
          })
          
        }
      })
      .catch((err) => {
        console.log("Errors", err);
        err.json().then(error => {
          setHasError(true);
          setErrorMessage(err)
        })
        // this.setState({
        //   hasError: true,
        //   errorMessage: err,
        // })
        
      });

  }

  // const { username } = this.state;

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          {/* <form onSubmit={this.handleSubmit}> */}
          <form onSubmit={handleSubmit}>
            <span className="login100-form-title p-b-48">
              <img height="auto" width={158} src={logo} alt="logo" />
            </span>
            <hr />
            <span className="login100-form-title p-b-26">
              Reset your password
            </span>
            {hasError && (
            <p className="text-center text-danger">{errorMessage}</p>
            )}
            {success && (
            <p className="text-center text-success">{successMessage}</p>
            )}
            <div className="wrap-input100 validate-input" data-validate="Valid email is: a@b.c">
              <input type="text" name="username"
                id="username" className="input100"
                value={username} onChange={e => setUsername(e.target.value)} />
              <span className="focus-input100" data-placeholder="Email"></span>
            </div>

            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button className="login100-form-btn">
                  Reset Password
							</button>
              </div>
            </div>

            <div className="text-center p-t-115">
              <span className="txt1">Remember your password?</span>
              <Link to="/login" class="txt2"> Login here! </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

}
export default PasswordResetForm;