import csvEncode from "./csvEncode";
import downloadUsingBlob from "./downloadUsingBlob";
import guid from "./guid";

export default function exportObjectsToCSV(data, columns) {
  let csvData = csvEncode(data, columns);
  let contentType = { type: "text/csv" };
  let fileName = `${guid()}.csv`;
  downloadUsingBlob(csvData, fileName, contentType);
}
