import React, { useEffect, useState, useContext } from "react";
import Router from '../router';
import config from '../env-config.json';
import { AuthContext } from '../state';
import { history } from '../history'

function App() {

  // constructor(props) {
  //     super(props)
  //     state = {
  //       intervalId : null
  //     }
  //   }

  const [intervalId, setIntervalId] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const { state, dispatch } = useContext(AuthContext);

  useEffect(() => {
    console.log("App state 1:", state.isAuthenticated, "accessToken: ", state.accessToken);
    // dispatch({ type: "LOGIN", payload: "hello" })
    // console.log("App state 2:", state.isAuthenticated);
  }, []);

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if(state.isAuthenticated) {
      history.push('/search');
    }
  }, [state.isAuthenticated])


  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user !== null) {
      var intervalId = setInterval(refresh(), 600000);
      setIntervalId(intervalId);
      setTimeout(() => {
        setLoaded(true)
      }, 800);
    }

    return () => { clearInterval(intervalId) }
  }, []);

  const refresh = () => {
    console.log("REFRESH_TOKEN ========================================")
    // let user = JSON.parse(localStorage.getItem('user'));
    fetch(config.auth_url + 'web/refresh/', {
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'App-Id': config.app_id,
      },
      // body: '{"refresh": "' + user.refresh + '"}'
    }).then(
      function (response) {
        if (response.status !== 200) {
          console.log("response", response)
          console.log('Status Code: ' + response.status);
          return;
        }
        response.json().then(function (data) {
          // localStorage.removeItem("user");
          // localStorage.setItem('user', JSON.stringify(data));
          dispatch({ type: "REFRESH", payload: data.access })
          
        });
      }
    ).catch(function (err) {
      console.log('Fetch Error :-S', err);
    });
  }

  const checkConfig = () => {
    if (config.auth_url && config.api_url) {
      return (<Router />)
    } else {
      return (renderNoConfig())
    }
  }

  const renderNoConfig = () => {
    return (
      <div>
        No config URL(s) provided. Please contact your admin to put correct configurations.
      </div>
    )
  }

  // componentWillUnmount() {
  //   clearInterval(this.state.intervalId);
  // }
  
  return (checkConfig())

}


export default App;