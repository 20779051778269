import React, { useState, useContext, useEffect } from "react";
import config from '../env-config.json';
import { Link } from "react-router-dom"
import ReactLoading from 'react-loading';
import { history } from '../history'
import { AuthContext } from '../state';
import Recaptcha from "react-recaptcha";

import '../styles/login.css';
import '../styles/util.css';
import logo from '../images/sasai-money-transfer-logo.png';

// const SITE_KEY = "6LdUN3cUAAAAAHpozRc6bYYE-bd-jskoLndCkiSm";
const SITE_KEY = config.google_recaptcha_key;

function LoginForm() {
    //   constructor() {
    //     super();
    //     this.state = {
    //       username: "",
    //       password: "",
    //       passType: "password",
    //       hasError: false,
    //       submitted: false,
    //       type: "bars",
    //       color: "#154989"

    //     };
    //   }

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passType, setPassType] = useState('password');
    const [hasError, setHasError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [type, setType] = useState('bars');
    const [color, setColor] = useState('#154989');
    const [errorMessage, setErrorMessage] = useState('');
    const [reCaptchaToken, setReCaptchaToken] = useState('');

    const { state, dispatch } = useContext(AuthContext);

    // const handleChange = (event) => {
    //     this.setState({ [event.target.id]: event.target.value });
    // }

    const verifyCallback = response => {
        console.log('verifyCallback', response);
        setReCaptchaToken(response);
    }

    const onloadCallback = () => {
        console.log('onloadCallback');
    }

    const handleOnClick = (event) => {
        // if (this.state.passType === 'password') {
        if (passType === 'password') {
            // this.setState({
            //     passType: "text"
            // })
            setPassType('text');
        } else {
            // this.setState({
            //     passType: "password"
            // })
            setPassType('password');
        }
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        // const { username, password } = this.state;
        // this.setState({
        //     submitted: true
        // })
        if(reCaptchaToken === '') {
            setSubmitted(false);
            setHasError(true);
            setErrorMessage('Please complete reCAPTCHA');
            return;
        }
        setSubmitted(true);

        fetch(config.auth_url + 'web/login/', {
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'ReCaptcha-Token' : reCaptchaToken,
                'App-Id': config.app_id,
            },
            body: '{"username": "' + username + '", "password": "' + password + '"}'
        }).then((response) => {
            //console.log(JSON.stringify(response))
            if (response.status === 400) {
                // this.setState({
                //     submitted: false,
                //     password: "",
                //     hasError: true,
                //     errorMessage: "Wrong username or password. Try again."
                // })
                setSubmitted(false);
                setPassword('');
                setHasError(true);
                setErrorMessage('Wrong username or password. Try again.');
                return;
            }
            response.json().then(function (data) {
                dispatch({ type: "LOGIN", payload: data.access })
                // localStorage.setItem('user', JSON.stringify(data));
                history.push('/search');
            });
        }
        ).catch((err) => {
            // this.setState({
            //     hasError: true,
            //     submitted: false,
            //     errorMessage: "General error occured. Try again"
            // })
            setHasError(true);
            setSubmitted(false);
            setErrorMessage('General error occured. Try again');
        });
    }




    const renderLoginForm = () => {
        // const { username, password, passType } = this.state;
        return (
            // <form onSubmit={this.handleSubmit}>
            <form onSubmit={handleSubmit}>
                <span className="login100-form-title p-b-48">
                    <img height="auto" width={158} src={logo} alt="logo" />
                </span>
                <hr />
                <span className="login100-form-title p-b-26">
                    Agent Login
                </span>
                {renderErrorMessage()}
                <div className="wrap-input100 validate-input" >
                    <input type="text" name="username"
                        id="username" className="input100"
                        // value={username} onChange={this.handleChange} />
                        value={username} onChange={e => setUsername(e.target.value)} />
                    <span className="focus-input100" data-placeholder="Email"></span>
                </div>
                <div className="wrap-input100 validate-input">
                    <span className="btn-show-pass">
                        <i className="fa fa-eye" onClick={handleOnClick}></i>
                    </span>
                    <input type={passType} name="password"
                        id="password" className="input100"
                        // value={password} onChange={this.handleChange} />
                        value={password} onChange={e => setPassword(e.target.value)} />
                    <span className="focus-input100" data-placeholder="Password"></span>
                </div>
                <div style={{ display: "flex", justifyContent: "center"}}>
                    <Recaptcha
                        sitekey={SITE_KEY}
                        render="explicit"
                        verifyCallback={verifyCallback}
                        onloadCallback={onloadCallback}
                    />
                </div>
                <div className="container-login100-form-btn">
                    <div className="wrap-login100-form-btn">
                        <div className="login100-form-bgbtn"></div>
                        <button className="login100-form-btn">Login</button>
                    </div>
                </div>
                <div className="text-center p-t-115">
                    <span className="txt1">Forgot your password?</span>
                    <Link to="/reset-password" className="txt2"> Reset here! </Link>
                </div>
            </form>
        );
    }

    const renderErrorMessage = () => {
        // const { hasError, errorMessage } = this.state;
        if (hasError) {
            return (
                <p className="text-center text-danger">{errorMessage}</p>
            )
        }
    }


    const renderSpinner = () => {
        // const { type, color } = this.state;
        // if (this.state.submitted) {
        if (submitted) {
            return (
                <div>
                    <span className="login100-form-title p-b-48">
                        <img height="auto" width={158} src={logo} alt="logo" />
                    </span>
                    <hr />
                    <span className="login100-form-title p-b-26">Signing in. Please wait...</span>
                    <ReactLoading className="login--spinner--100" type={type} color={color} height={300} width={150} />
                </div>
            )
        }
    }

    // const { submitted } = this.state;
    let display;

    if (submitted) {
        display = renderSpinner();
    } else {
        display = renderLoginForm();
    }
    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    {display}
                </div>
            </div>
        </div>
    );
}

export default LoginForm;
