import React, { useEffect, useState, useContext } from "react";
import { history } from "../history/index";
import ReactLoading from 'react-loading';
import config from '../env-config.json';
import ServerData from "../utils/ServerData";
import '../styles/global.css';
import '../styles/transaction.css';
import { AuthContext } from "../state";

function SearchTransactionForm() {

    // constructor() {
    //     super();
    //     this.state = {
    //         reference: "",
    //         submitted: false,
    //         loaderType: "cylon",
    //         loaderColor: "#154989",
    //         errorMessage: "",
    //         hasError: false,
    //         buttonEnabled: false,
    //         formHasError: false,
    //         formError: 'Transaction reference not valid'
    //     };
    // }

    const [reference, setReference] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [loaderType, setLoaderType] = useState('cylon');
    const [loaderColor, setLoaderColor] = useState('#154989');
    const [errorMessage, setErrorMessage] = useState('');
    const [hasError, setHasError] = useState(false);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [formHasError, setFormHasError] = useState(false);
    const [formError, setFormError] = useState('Transaction reference not valid');

    const { state } = useContext(AuthContext);

    const handleChange = (event) => {
        if (event.target.value.length < 5) {
            // this.setState({
            //     reference: event.target.value,
            //     formHasError: true,
            //     buttonEnabled: false
            // })
            setReference(event.target.value);
            setFormError(true);
            setButtonEnabled(false);
        } else {
            // this.setState({
            //     reference: event.target.value,
            //     buttonEnabled: true
            // });
            setReference(event.target.value);
            setButtonEnabled(true);
        }

    }

    const checkIfTransactionIs = (reference) => {
        fetch(config.api_url + 'transactions/' + reference +"/",
            // ServerData.getAuthHeaders())
            {
                mode: 'cors',
                credentials: 'include',
                method: 'GET',
                headers: {'Content-Type': 'application/vnd.api+json',
                        'Accept': 'application/vnd.api+json',
                        'Authorization': 'Bearer ' + state.accessToken,
                        'App-Id': config.app_id,}
            })
            .then(response => {
                console.log(response);
                if(response.status === 404){
                    // this.setState({
                    //     submitted: false,
                    //     hasError: true,
                    //     errorMessage: "Transaction not found, please try again."
                    // })
                    setSubmitted(false);
                    setHasError(true);
                    setErrorMessage('Transaction not found, please try again.');

                } else {
                    history.push({
                        pathname: "/transaction/" + reference,
                    });
                }
            });
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        // const { reference } = this.state;
        // this.setState({
        //     submitted: true
        // })
        setSubmitted(true)
        // this.checkIfTransactionIs(reference);
        checkIfTransactionIs(reference);

        //console.log(this.props)
        // fetch(config.api_url + 'transactions/' + reference + 
        // fetch(config.api_url + 'transactions?filter[code]=' + reference +
        //     '&include=currency,sender,sender.country,beneficiary,beneficiary.country',
        //     // '/&include=currency,sender,sender.country,beneficiary,beneficiary.country,available_payout_currencies',
        //     ServerData.getAuthHeaders()
        // ).then((response) => {
        //     console.log("resp", response)
        //     if (response.status === "200" || response.status === 200) {
        //         this.setState({
        //             submitted: false
        //         })
        //         response.json().then(function (data) {
        //             history.push({
        //                 pathname: "/transaction/" + reference,
        //             });
        //         });
        //     } else if (response.status === "404" || response.status === 404) {
        //         this.setState({
        //             submitted: false,
        //             hasError: true,
        //             errorMessage: "Transaction not found. Please check reference and try again."
        //         })

        //     } else if (response.status === "403" || response.status === 403) {
        //         this.setState({
        //             submitted: false,
        //             hasError: true,
        //             errorMessage: "Not allowed to access this resource. Try again, if problem persist contact admin."
        //         })

        //     } else {
        //         this.setState({
        //             submitted: false,
        //             hasError: true,
        //             errorMessage: "General processing error. Try again or contact admin."
        //         })
        //     }
        // }).then((data) => {
        // }
        // ).catch(function (err) {
        //     console.log('Fetch Error :-S', err);

        // });
    }

        


    const renderSearching = () => {
        // const { loaderType, loaderColor, reference } = this.state;
        // if (this.state.submitted) {
        if (submitted) {
            console.log("We are spinning here >>>>>>>>>>>>>> " + loaderType)
            return (
                <div className="col-6 offset-sm-3">
                    <p> We are searching for transaction with reference : {reference}. Please wait...</p>
                    <ReactLoading className="searching--spinner--100" type={loaderType} color={loaderColor} height={200} width={200} />
                </div>
            );
        }

    }

    const renderErrorMessage = () => {
        // const { errorMessage, hasError, submitted } = this.state;
        // console.log("state", this.state)
        if (!submitted && hasError) {
            return (
                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                    <strong>Error: </strong> {errorMessage}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        }
    }

    // const { reference, buttonEnabled } = this.state;
    return (
        <div className="our-causes pt-0">
            <div className="my-content">
                <div className="row">
                    <div className="col-12 ">
                        <div className="cause-wrap">
                            <div className="cause-content-wrap">
                                <header className="entry-header d-flex flex-wrap align-items-center">
                                    <h3 className="entry-title w-100 m-0">

                                    </h3>
                                </header>
                                <div className="entry-content">
                                    <div className="col-6 offset-sm-3">
                                        {/* <form className="form-inline" onSubmit={this.handleSubmit}> */}
                                        <form className="form-inline" onSubmit={handleSubmit}>
                                            <div className="form-group mb-2">
                                                <input type="text" className="form-control-plaintext" id="staticEmail2" value="Enter beneficiary reference" readOnly={true} />
                                            </div>
                                            <div className="form-group mx-sm-3 mb-2">
                                                <input type="text" className="form-control" name="reference" placeholder="Beneficiary reference"
                                                    // value={reference} onChange={this.handleChange} />
                                                    value={reference} onChange={handleChange} />

                                            </div>
                                            <button type="submit" className="btn btn-primary mb-2" disabled={!buttonEnabled}>Search </button>
                                        </form>
                                    </div>
                                    <div className="col-12">
                                        <hr></hr>
                                        {/* {this.renderSearching()} */}
                                        {renderSearching()}
                                        {/* {this.renderErrorMessage()} */}
                                        {renderErrorMessage()}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default SearchTransactionForm;
