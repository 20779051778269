import React, { useReducer } from "react";

let reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return { ...state, isAuthenticated: true, accessToken: action.payload };
        case "REFRESH":
            return { ...state, isAuthenticated: true, accessToken: action.payload };
        case "LOGOUT":
            return { ...state, isAuthenticated: false, accessToken: action.payload, isManager: false };
        case "IS_MANAGER":
            return { ...state, isManager: true };
        default:
            return state;
    }
};

const initialState = { isAuthenticated: false, accessToken: '', isManager: false }

const AuthContext = React.createContext(initialState);

function AuthProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };