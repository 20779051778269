import React, { } from "react";
import EnterResetPasswordForm from '../containers/EnterResetPasswordForm';


function EnterResetPassword(props) {

    return (
        <EnterResetPasswordForm {...props} />
    );
}

export default EnterResetPassword;