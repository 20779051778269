export default function csvEncode(data, columns) {
    let csv = [];
    if (data && data.length && columns && columns.length) {
        let headers = [];
        for (let i = 0; i < columns.length; i++) {

            let column = columns[i];
            let { header } = column;
            if (header && header instanceof String) {
                header = header.replace(/\\/g, '\\\\').replace(/\n/g, '\\n').replace(/\r/mg, '\\r').replace(/\n/mg, '\\n').replace(/\t/mg, '\\t');
            }
            headers.push(header);

        }
        csv.push(headers.join(','));

        for (let i = 0; i < data.length; i++) {
            let obj = data[i];
            let row = [];
            for (var j = 0; j < columns.length; j++) {
                let column = columns[j];
                let { accessor, cast } = column;
                let cell = obj[accessor];

                if (cast === 'string') {
                    cell = '"' + cell.replace(/\\/g, '\\\\').replace(/\n/g, '\\n').replace(/\r/mg, '\\r').replace(/\n/mg, '\\n').replace(/\t/mg, '\\t') + '"';
                }
                row.push(cell);

            }
            csv.push(row.join(','));
        }
    }
    return csv.join('\n');
}
