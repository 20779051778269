import React, { useState, useEffect, useContext } from "react";
import ServerData from "../utils/ServerData";
import { Link } from 'react-router-dom';
import { history } from "../history/index";
import config from '../env-config.json';

import '../styles/global.css';
import '../styles/transaction.css';
import { AuthContext } from "../state";

function ChangePasswordForm() {
    // state = {
    //         oldPassword: "",
    //         newPassword: "",
    //         newPassword2: "",
    //         passwordChanged: false,
    //         userId: "",
    //         userCode: "",
    //         hasError: false,
    //         errorMessage: ""

    //     }

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [userId, setUserId] = useState('');
    const [userCode, setUserCode] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const { state, dispatch } = useContext(AuthContext);

    // const handleChange=(event)=> {
    //     this.setState({ [event.target.id]: event.target.value });
    // }

    useEffect(() => {
        // fetch(config.api_url + 'profile/', ServerData.getAuthHeaders())
        fetch(config.api_url + 'profile/', 
        {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    'Authorization': 'Bearer ' + state.accessToken,
                    'App-Id': config.app_id,}
        })
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(JSON.stringify(result))
                    // this.setState({
                    //    userCode: result.data.attributes.code,
                    //    userId: result.data.id
                    // });
                    setUserCode(result.data.attributes.code);
                    setUserId(result.data.id)
                }
            ).catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }, []);

    const handleChangePassword = (event) => {
        event.preventDefault();
        // const { oldPassword, newPassword, newPassword2 } = this.state;

        if (newPassword === newPassword2) {
            let data = {
                data: {
                    type: "passwords",
                    // id: this.state.userCode,
                    id: userCode,
                    attributes: {
                        password: oldPassword,
                        new_password: newPassword
                    }
                }
            };

            console.log(JSON.stringify(data));
            // let user = JSON.parse(localStorage.getItem('user'));

            // fetch(config.api_url + 'accounts/' + this.state.userId + '/password/', {
            fetch(config.api_url + 'accounts/' + userId + '/password/', {
                mode: 'cors',
                credentials: 'include',
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    'Authorization': 'Bearer ' + state.accessToken,
                    'App-Id': config.app_id,
                },
                body: JSON.stringify(data)
            }).then((response) => {
                if (response.status === 200) {
                    // this.setState({
                    //     passwordChanged: true
                    // })
                    setPasswordChanged(true);
                    return;
                }
            }
            ).catch((err) => {
                // this.setState({
                //     hasError:true,
                //     submitted: false,
                //     errorMessage: "General error occured. Try again"
                // })
                setHasError(true);
                setSubmitted(false);
                setErrorMessage("General error occured. Try again");
            });
        } else {
            // this.setState({
            //     hasError: true,
            //     errorMessage: "Passwords didn't match. Try again."
            // });
            setHasError(true);
            setErrorMessage("Passwords didn't match. Try again.");
        }

    }




    const renderChangePassword = () => {
        // const { oldPassword, newPassword, newPassword2} = this.state;
        if (passwordChanged) {
            return (
                <div className="col-12">
                    <div className="card text-center" style={{ marginTop: "20px" }}>
                        <div className="card-header">
                            Password Changed
                        </div>
                        <div className="card-body text-left">
                            <div className="alert alert-primary" role="alert">
                                <h4 className="alert-heading">Password changed successfully!</h4>
                                <p> To login and refresh your credentials <Link to="" onClick={() => onLogout("")} >  click here  </Link>  </p>
                                <hr />

                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col-12">
                    <div className="card" style={{ marginTop: "20px", paddingBottom: "30px" }}>
                        <div className="card-header">
                            Change Account Password
                    </div>
                        <div className="card-body text-left">

                            <div className="col-sm-6 offset-sm-3">
                                {renderErrorMessage()}

                                {/* <form onSubmit={this.handleChangePassword}> */}
                                <form onSubmit={handleChangePassword}>

                                    <div className="form-group">
                                        <label>Current Password (*)</label>
                                        <input name="oldPassword" id="oldPassword" type="password" className="form-control ftt-form"
                                            value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>New Password (*)</label>
                                        <input name="newPassword" id="newPassword" type="password" className="form-control ftt-form"
                                            value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Re-type New Password(*)</label>
                                        <input name="newPassword2" id="newPassword2" type="password" className="form-control ftt-form"
                                            value={newPassword2} onChange={e => setNewPassword2(e.target.value)} />
                                    </div>

                                    <button type="submit" className="btn btn-success btn-block">
                                        Change Password
                                    </button>
                                </form>
                            </div>

                        </div>

                    </div>
                </div>
            )
        }

    }


    const renderErrorMessage = () => {
        // const { hasError, errorMessage } = this.state;
        if (hasError) {
            return (
                <p className="text-center text-danger">{errorMessage}</p>
            )
        }
    }

    const onLogout = () => {
        // let user = localStorage.getItem("user");
        // localStorage.removeItem("user");
        // localStorage.removeItem('isManager');
        dispatch({ type: "LOGOUT", payload: "" });
        history.push({
            pathname: "/login",
            state: {
                //message : message
            }
        });
    }

    return (
        <div className="our-causes pt-0">
            <div className="container">
                <div className="row">

                    {renderChangePassword()}
                </div>
            </div>
        </div>
    );

}

export default ChangePasswordForm;
