import React from "react";
import LoginForm from '../containers/LoginForm';


function LoginPage() {

  return (
    <LoginForm />
  );
}

export default LoginPage;
