import React, { useState, useContext } from "react";
import ServerData from "../utils/ServerData";
import { Link } from 'react-router-dom';
import { history } from "../history/index";
import config from '../env-config.json';

import '../styles/global.css';
import '../styles/transaction.css';

function EnterResetPasswordForm(props) {
   
    // state = {
    //     newPassword: "",
    //     newPassword2: "",
    //     passwordChanged: false,
    //     userId: "",
    //     userCode: "",
    //     hasError: false,
    //     errorMessage: ""

    // }
    
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);
    // const [userId, setUserId] = useState('');
    // const [userCode, setUserCode] = useState('');
    const [hasError, setHasError] = useState(false);
    const [setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    // handleChange = (event) => {
    //     this.setState({ [event.target.id]: event.target.value });
    // }

    // componentWillMount() {
        // fetch(config.api_url + 'profile/', ServerData.getAuthHeaders())
        // .then(res => res.json())
        // .then(
        //     (result) => {
        //        // console.log(JSON.stringify(result))
        //         this.setState({
        //            userCode: result.data.attributes.code,
        //            userId: result.data.id
        //         });
        //     }
        // ).catch(function(err) {
        //     console.log('Fetch Error :-S', err);
        // });
    // }

    const handleChangePassword = (event) => {
        event.preventDefault();
        // const { newPassword, newPassword2 } = this.state;

        if (newPassword === newPassword2) {
            // let data = {
            //     data: {
            //         type: "passwords",
            //         id: this.state.userCode,
            //         attributes: {
            //             // password: oldPassword,
            //             new_password: newPassword
            //         }
            //     }
            // };

            let data = {
                data: {
                    type: "passwords",
                    id: props.match.params.uid,
                    attributes: {
                        password: newPassword,
                        token: props.match.params.token
                    }
                }
            }

            console.log(JSON.stringify(data));
            let user = JSON.parse(localStorage.getItem('user'));

            fetch(config.api_url + 'email/passwords/' + props.match.params.uid + '/', {
                // mode: 'cors',
                // credentials: 'include',
                method: 'PUT',
                // headers: {'Content-Type': 'application/vnd.api+json',
                //             'Accept': 'application/vnd.api+json',
                //             'Authorization': 'Bearer ' + user.access},
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    'App-Id': config.app_id,
                },
                body: JSON.stringify(data)
            }).then((response) => {
                if (response.status === 200) {
                    // this.setState({
                    //     passwordChanged: true
                    // })
                    setPasswordChanged(true);
                    return;
                } else if (response.status === 400) {
                    response.json().then(res => {
                        console.log(res)
                        // this.setState({
                        //     hasError: true,
                        //     errorMessage: res.errors[0].detail,
                        // },
                        // console.log("errorMessage", this.state.errorMessage))
                        setHasError(true);
                        setErrorMessage(res.errors[0].detail);
                    })
                }
            }
            ).catch((err) => {
                console.log("caught error...")
                // this.setState({
                //     hasError: true,
                //     submitted: false,
                //     errorMessage: "General error occured. Try again"
                // })
                setHasError(true);
                setSubmitted(false);
                setErrorMessage('General error occured. Try again');
            });
        } else {
            // this.setState({
            //     hasError: true,
            //     errorMessage: "Passwords didn't match. Try again."
            // });
            setHasError(true);
            setErrorMessage("Passwords didn't match. Try again.");
        }

    }

       



    const renderChangePassword = () => {
        // const { newPassword, newPassword2 } = this.state;
        if (passwordChanged) {
            return (
                <div className="col-12">
                    <div className="card text-center" style={{ marginTop: "20px" }}>
                        <div className="card-header">
                            Password Changed
                        </div>
                        <div className="card-body text-left">
                            <div className="alert alert-primary" role="alert">
                                <h4 className="alert-heading">Password changed successfully!</h4>
                                <p> To login and refresh your credentials <Link to="" onClick={() => onLogout("")} >  click here  </Link>  </p>
                                <hr />

                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col-12">
                    <div className="card" style={{ marginTop: "20px", paddingBottom: "30px" }}>
                        <div className="card-header">
                            Enter new password
                        </div>

                        <div className="card-body text-left">

                            <div className="col-sm-6 offset-sm-3">
                                {renderErrorMessage()}

                                <div className="font-weight-bold">
                                    <p>Password must contain a minimum of 8 characters and one number.</p>
                                </div>

                                <form onSubmit={handleChangePassword}>

                                    <div className="form-group">
                                        <label>New Password (*)</label>
                                        <input name="newPassword" id="newPassword" type="password" className="form-control ftt-form"
                                            value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                    </div>

                                    <div className="form-group">
                                        <label>Re-type New Password(*)</label>
                                        <input name="newPassword2" id="newPassword2" type="password" className="form-control ftt-form"
                                            value={newPassword2} onChange={e => setNewPassword2(e.target.value)} />
                                    </div>

                                    <button type="submit" className="btn btn-success btn-block" disabled={!newPassword} style={{ cursor: !newPassword && "not-allowed"}}>
                                        Change Password
                                    </button>
                                </form>
                            </div>

                        </div>

                    </div>
                </div>
            )
        }

    }


    const renderErrorMessage = () => {
        // const { hasError, errorMessage } = this.state;
        if (hasError) {
            return (
                <p className="text-center text-danger">{errorMessage}</p>
            )
        }
    }

    const onLogout = () => {
        // let user = localStorage.getItem("user");
        localStorage.removeItem("user");
        history.push({
            pathname: "/login",
            // state: {
            //     //message : message
            // }
        });
    }

    return (
        <div className="our-causes pt-0">
            <div className="container">
                <div className="row">

                    {renderChangePassword()}
                </div>
            </div>
        </div>
    );

}
export default EnterResetPasswordForm;
