import React from 'react';
import { Router, Route, Switch } from 'react-router-dom'
import {PrivateRoute } from './components/PrivateRoute';

import {history} from './history/index';

import Home from './components/home.js';
import Profile from './components/profile.js';
import ChangePassword from './components/change_password.js';
import LoginPage from './components/login.js';
import ResetPassword from './components/reset-password.js';
import TransactionList from './components/transactions';
import TransactionDetail from './components/transaction-details';
import PrintReceipt from './components/print-receipt';
import SearchTransaction from './components/search-transaction';
import CashoutReport from './components/cashout_report';
import EnterResetPassword from './components/enter-reset-password.js';

export default () => (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/reset/:uid/:token/" component={EnterResetPassword} />  
        {/* <PrivateRoute exact path="/home" component={Home}/> */}
        <PrivateRoute exact path="/profile" component={Profile}/>
        <PrivateRoute exact path="/change_password" component={ChangePassword}/>
        <PrivateRoute exact path='/transaction/:id' component={TransactionDetail}/>
        <Route path="/login" component={LoginPage} />
        <Route path="/reset-password" component={ResetPassword} />
        <PrivateRoute path="/transactions" component={TransactionList} />
        <PrivateRoute path="/cashout-report" component={CashoutReport} />
        <PrivateRoute path="/search" component={SearchTransaction} />
        <PrivateRoute path="/print" component={PrintReceipt} />
      </Switch>
    </Router>
 );