import React from 'react';
import {render} from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import App from './components/app';
import { AuthProvider } from './state';


render( 
  <AuthProvider>
      <App />
  </AuthProvider>
, document.getElementById("root")
  );

  
