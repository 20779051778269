import React from "react";
import SearchTransactionForm from '../containers/SearchTransactionForm';
import Header from '../containers/Header';


function SearchTransaction() {

  return (
    <div>
      <Header />
      <SearchTransactionForm />
    </div>

  );
}

export default SearchTransaction;
