import React, { useState, useEffect, useContext } from "react";
import ServerData from "../utils/ServerData";
import config from '../env-config.json';
import { Link } from "react-router-dom"

import '../styles/global.css';
import '../styles/transaction.css';
import { AuthContext } from "../state";

function ProfileForm(props) {
    //   constructor(props) {
    //     super(props);
    //     this.state = {
    //       profile: {
    //         firstName: '',
    //         lastName: '',
    //         middleName: '',
    //         email: '',
    //         agent:0,
    //         branch: 0
    //     },
    //     branchData: {
    //         name: '',
    //         city: '',
    //         branch_name: ''
    //     }
    //     }
    //   }

    const [profileFirstName, setProfileFirstName] = useState('');
    const [profileLastName, setProfileLasttName] = useState('');
    const [profileMiddleName, setProfileMiddleName] = useState('');
    const [profileEmail, setProfileEmail] = useState('');
    const [profileAgent, setProfileAgent] = useState(0);
    const [profileBranch, setProfileBranch] = useState(0);
    const [branchDataName, setBranchDataName] = useState('');
    const [branchDataCity, setBranchDataCity] = useState('');
    const [branchDataBranchName, setBranchDataBranchName] = useState('');

    const { state } = useContext(AuthContext);

    // component will mount life cycle hook
    useEffect(() => {

        // fetch(config.api_url + 'profile/', ServerData.getAuthHeaders())
        fetch(config.api_url + 'profile/', 
        {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    'Authorization': 'Bearer ' + state.accessToken,
                    'App-Id': config.app_id,}
        })
            .then(res => res.json())
            .then(
                (result) => {
                    // this.setState({
                    //     profile: {
                    //         firstName: result.data.attributes.first_name,
                    //         middleName: result.data.attributes.middle_name,
                    //         lastName: result.data.attributes.last_name,
                    //         email: result.data.attributes.email,
                    //         agent: result.data.relationships.cash_pickup_provider,
                    //         branch: result.data.relationships.cash_pickup_location
                    //     }
                    // });

                    setProfileFirstName(result.data.attributes.first_name);
                    setProfileMiddleName(result.data.attributes.middle_name);
                    setProfileLasttName(result.data.attributes.last_name);
                    setProfileAgent(result.data.relationships.cash_pickup_provider);
                    setProfileBranch(result.data.relationships.cash_pickup_location);
                    setProfileEmail(result.data.attributes.email);

                    
                },
            ).catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    // }, [profileFirstName, profileMiddleName, profileLastName, profileAgent, profileBranch, profileEmail])
    }, [])
    
    useEffect(() => {
        if (profileBranch != undefined) {
            if (profileBranch.data != undefined) {
            let branch_id = profileBranch.data.id
            fetch(config.api_url + 'cash_pickup_locations/' + branch_id + '?include=country,provider')
                .then(ress => ress.json())
                .then(
                    (results) => {
                        // this.setState({
                        //     branchData: {
                        //         branch_name: results.data.attributes.name,
                        //         city: results.data.attributes.city,
                        //         name: results.included[0].attributes.name
                        //     }
                        // })

                        setBranchDataBranchName(results.data.attributes.name);
                        setBranchDataCity(results.data.attributes.city);
                        setBranchDataName(results.included[0].attributes.name);
                    }
                )}
        }
    }, [profileBranch])
       


    const renderProfile = () => {
        return (
            <div className="col-12 ">
                <div className="card" style={{ marginTop: "40px" }}>
                    <div className="card-body">
                        <h5 className="card-title">My Profile </h5>
                        <hr></hr>
                        <div className="row">
                            <div className="col-6">
                                <p className="card-text"><b>Full Name: </b></p>

                            </div>
                            <div className="col-6">
                                <p className="card-text">
                                    {/* {this.state.profile.firstName} {this.state.profile.lastName} */}
                                    {profileFirstName} {profileLastName}
                                </p>

                            </div>
                            <div className="col-12" style={{ marginTop: "15px" }}></div>

                            <div className="col-6">
                                <p className="card-text"><b>Provider: </b></p>

                            </div>
                            <div className="col-6">
                                <p className="card-text">
                                    {/* {this.state.branchData.name} */}
                                    {branchDataName}
                                </p>

                            </div>
                            <div className="col-12" style={{ marginTop: "15px" }}></div>

                            <div className="col-6">
                                <p className="card-text"><b>Branch: </b></p>

                            </div>
                            <div className="col-6">
                                <p className="card-text">
                                    {/* {this.state.branchData.branch_name} */}
                                    {branchDataBranchName}
                                </p>

                            </div>
                            <div className="col-12" style={{ marginTop: "15px" }}></div>

                            <div className="col-6">
                                <p className="card-text"><b>Email: </b></p>

                            </div>
                            <div className="col-6">
                                <p className="card-text">
                                    {profileEmail}
                                </p>

                            </div>

                            <div className="col-12" style={{ marginTop: "15px" }}>
                            </div>

                            <div className="col-6">
                                <p className="card-text"><b>Location: </b></p>

                            </div>
                            <div className="col-6">
                                <p className="card-text">Zimbabwe, ZW</p>

                            </div>
                            <div className="col-12">
                                <hr></hr>
                                <p className="card-text"> To change your password
                                    <Link to="/change_password"> click here</Link>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="our-causes pt-0">
            <div className="container">
                <div className="row">
                    {renderProfile()}
                </div>
            </div>
        </div>
    );

}
export default ProfileForm;
