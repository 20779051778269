import React, { Component } from 'react';
import axios from 'axios';
import {history} from '../history'
import { AuthContext } from '../state';
import config from '../env-config.json'

class ServerData extends Component { 

    static contextType = AuthContext;

    // static login(username, password) {
    //     console.log(username);
    //     console.log(password);
    //     let user = { username: username , password:  password };

    //     console.log(user);
    //     axios.post(`https://test20-za.securemit.com/auth/v1/login/`, 
    //     user,
    //     {
    //         mode: 'cors',
    //         credentials: 'include',
    //         headers: {'Content-Type': 'application/json',
    //                   'Accept': 'application/json'}
    //     })
    //     .then(res => {
    //             localStorage.setItem('user', JSON.stringify(res.data));
    //             history.push('/home');
    //     })
    //     .catch(error => {
    //         console.log(error.response)
    //         return "error";
    //     });
    // }

    // static refresh() {
    //     let user = JSON.parse(localStorage.getItem('user'));
    //     fetch('https://test20-za.securemit.com/auth/v1/refresh/', {
    //         mode: 'cors',
    //         credentials: 'include',
    //         method: 'POST',
    //         headers: {'Content-Type': 'application/json',
    //                 'Accept': 'application/json'},
    //         body: '{"refresh": "' + user.refresh + '"}'
    //     }).then(
    //         function(response) {
    //             if (response.status !== 200) {
    //                 console.log('Status Code: ' + response.status);
    //                 return;
    //             }
    //             response.json().then(function(data) {
    //                 localStorage.removeItem("user");
    //                 localStorage.setItem('user', JSON.stringify(data));
    //             });
    //         }
    //     ).catch(function(err) {
    //         console.log('Fetch Error :-S', err);
    //     });
    // }


    /*
    static getProfile(){
        let user = JSON.parse(localStorage.getItem('user'));
         fetch('https://test20-za.securemit.com/api/v1/profile/', {
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/vnd.api+json',
                      'Accept': 'application/vnd.api+json',
                      'Authorization': 'Bearer ' + user.access},
        }).then(res => res.json())
        .then(
          (result) => {
            console.log(JSON.stringify(result));
          },
        ).catch(function(err) {
            console.log('Fetch Error :-S', err);
        });

    }

    */

    static transactionList(trans) {
        //console.log(JSON.stringify(trans))
        var transData = [];
        let status = "";
        //let trans = result.data;
        trans.forEach(element => {
            let stat = element.attributes.status;
            if(stat === 'A'){
                status = "AUTHORISED";
            }else if(stat === 'C'){
                status = "COMPLETE";
            }else if(stat === 'P'){
                status = "PENDING";
            }else {
                status = "STATUS";
            }
            //if(trans.relationships.sender.data.id === result.included[count].)
            transData.push({
                sender: "Test Sender ",
                beneficiary: "Test Recipient ",
                 code: element.attributes.code,
                 amount: element.attributes.amount,
                 id: element.id,
                 status: status
            })            
        });
        return transData;
    }

    // static getAuthHeaders(){
    //     let user = JSON.parse(localStorage.getItem('user'));
    //     return  {
    //         mode: 'cors',
    //         credentials: 'include',
    //         method: 'GET',
    //         headers: {'Content-Type': 'application/vnd.api+json',
    //                 'Accept': 'application/vnd.api+json',
    //                 'Authorization': 'Bearer ' + user.access}
    //     }
    // }
    // static getAuthPutHeaders(){
    //     let user = JSON.parse(localStorage.getItem('user'));
    //     return  {
    //         mode: 'cors',
    //         credentials: 'include',
    //         method: 'PUT',
    //         headers: {'Content-Type': 'application/vnd.api+json',
    //                 'Accept': 'application/vnd.api+json',
    //                 'Authorization': 'Bearer ' + user.access}
    //     }
    // }
}

export default ServerData;