import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import Header from '../containers/Header';
import Receipt from '../containers/Receipt';
import '../styles/global.css';
import '../styles/transaction.css';


function PrintReceipt(props) {
    // constructor(props) {
    //   super(props);
    //   this.merchant = React.createRef();

    // }

    // componentDidMount(){

    // }


    const printReceipt = () => {
        //var merchantCont = document.getElementById("merchant");
        /*
        if (x.style.display === "none") {
            x.style.display = "";
        } else {
            x.style.display = "none";
        }
        var printContents = document.getElementById("printArea").innerHTML;
        var x = document.getElementById("merchant");
        if (x.style.display === "none") {
            x.style.display = "";
        } else {
            x.style.display = "none";
        }
        document.body.innerHTML = printContents;
        */
        window.print();
        //document.body.innerHTML = originalContents;
    }

    const componentRef = useRef();

    return (
        <div>
            <Header />
            <div className="contact-page-wrap">
                <div className="container">
                    <div className="row" style={{ marginTop: "50px" }}>
                        <div className="col-6 offset-md-3">
                            <div id="printableArea">

                                <Receipt ref={componentRef}  {...props} />

                                <ReactToPrint
                                    trigger={() => <button style={{ marginRight: '30%', marginTop: '10px', marginLeft: '30%', marginBottom: '10%' }}
                                        type="button" className="btn btn-primary">Print Receipt</button>}
                                    content={() => componentRef.current}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrintReceipt;